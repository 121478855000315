import gsap from 'gsap'
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

import { graphql } from 'gatsby'

import ModalLayout from '../components/Layouts/ModalLayout'
import LocalizedTransitionLink from '../components/LocalizedTransitionLink'

import { internationalRoutes } from '../utils/helpers/routes'
import { getPackColor } from '../utils/helpers/utils'
import {
  getPageData,
  getPrismicListDocument,
} from '../utils/helpers/transformers'
import { HistoryContext } from '../utils/hooks/History'
import { animateEntry, setEntry } from '../utils/page-animations/packages'
import useAnimationState from '../utils/hooks/useAnimationState'

import ScrollTrigger from 'gsap/ScrollTrigger'

const Packages = (props) => {
  const { data, pageContext } = props
  const { routeName, language } = pageContext

  // INTN
  const { t } = useTranslation(['common'])
  const localizedRoutePackages = internationalRoutes[routeName].langs[language]
  const allOption = useMemo(() => t('common:filters:all'), [t])
  const filters = useMemo(
    () => [allOption, 'design', 'strategy', 'tech'],
    [allOption]
  )
  const pageTitle = t('common:statements:pack:title')
  const headerBgColor = '#e9e9e9'
  const seo = {
    title: t('packages:seo:title'),
    description: t('packages:seo:description'),
  }

  // ANIMATION
  const animationState = useAnimationState({ routeName })

  //CONTEXT
  const { isLandingPage } = useContext(HistoryContext)

  // DATA

  const pageData = useMemo(() => getPageData(data, 'homePage'), [data])
  const packs = useMemo(
    () => getPrismicListDocument(pageData, 'packs', 'pack'),
    [pageData]
  )

  const needsLandingContent = useMemo(
    () => animationState.isEntryPage && isLandingPage,
    [animationState.isEntryPage, isLandingPage]
  )

  // REFS
  const $hasRunAnimation = useRef(false)
  const filterTl = useRef(null)
  const $listPack = useRef(null)

  //STATE
  const [activeFilter, setActiveFilter] = useState(allOption)

  // DATA
  const filteredPacks = useMemo(() => {
    if (activeFilter === allOption) {
      return packs
    }

    return packs.filter((pack) => pack.data.field === activeFilter)
  }, [allOption, activeFilter, packs])

  const onClickFilter = useCallback((filter) => {
    if (filterTl.current) {
      filterTl.current.pause()
      filterTl.current.kill()
    }
    filterTl.current = gsap.timeline()
    filterTl.current.pause()
    $listPack.current &&
      filterTl.current.to(
        $listPack.current,
        {
          opacity: 0,
          duration: 0.3,
          onComplete: () => {
            setActiveFilter(filter)
          },
        },
        0
      )
    $listPack.current &&
      filterTl.current.to($listPack.current, { opacity: 1, duration: 0.3 }, 0.3)
    filterTl.current.resume()
  }, [])

  //HOOKS
  useEffect(() => {
    if (!animationState.canStart) {
      return
    }
    ScrollTrigger.clearScrollMemory()
    setEntry()
  }, [animationState.canStart])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }

    const tl = animateEntry()
    $hasRunAnimation.current = true
    return () => tl && tl.kill()
  }, [animationState.canPlay])

  return (
    <ModalLayout
      name={routeName}
      uri={props.uri}
      seo={seo}
      title={pageTitle}
      headerBgColor={headerBgColor}
      language={language}
      internationalRoute={pageContext.internationalRoute}
      isLandingPage={needsLandingContent}
    >
      <section className="pt-3 pb-5 pt-md-8 pb-md-4">
        <div className="container">
          <div className="row">
            <div className="col col-lg-offset-1 col-lg-3">
              <ul className="list list--filters mb-7 mb-lg-0">
                {filters.map((item) => (
                  <li
                    key={item}
                    className="list__item mb-lg-4 ft-primary-m-small"
                  >
                    <button
                      onClick={() => onClickFilter(item)}
                      className={`ft-cap c-black  ${
                        activeFilter === item ? 'is-active' : ''
                      }`}
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col col-lg-9">
              <ul className="list-pack" ref={$listPack}>
                {filteredPacks.map((pack) => {
                  const packColor = getPackColor(pack.data.field)
                  const styleImage = { background: packColor }
                  const styleType = { color: packColor }
                  const content = (
                    <>
                      <div className="list-pack__left">
                        <div className="list-pack__image" style={styleImage}>
                          <img
                            src={`/images/pack-${pack.data.field}.svg`}
                            alt="Pack Source"
                            width="30"
                            height="20"
                          />
                        </div>
                        <div className="list-pack__infos">
                          <div className="ft-default list-pack__title">
                            {pack.data?.name?.text}
                          </div>
                          <div className="ft-secondary f f-wrap f-gap-16 list-pack__pricing">
                            <span className="ft-cap" style={styleType}>
                              {pack.data?.field}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="list-pack__right mt-2 mt-md-0">
                        <h2 className="ft-400 ft-f-default ft-default">
                          {pack.data?.title?.text}
                        </h2>
                      </div>
                    </>
                  )

                  return (
                    <li className="list-pack__item" key={pack.uid}>
                      {pack.data.has_page ? (
                        <LocalizedTransitionLink
                          key={pack.uid}
                          entryTransitionType={'slideIn'}
                          exitTransitionType={'none'}
                          to={`${localizedRoutePackages}/${pack.uid}`}
                          className="list-pack__content"
                        >
                          {content}
                        </LocalizedTransitionLink>
                      ) : (
                        <div className="list-pack__content">{content}</div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </ModalLayout>
  )
}

export default Packages

export const query = graphql`
  query ($language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicPack: allPrismicPack(
      sort: { fields: data___field, order: ASC }
      filter: { lang: { eq: $contentLang } }
    ) {
      ...prismicPack
    }
    homePage: allPrismicHomePage(filter: { lang: { eq: $contentLang } }) {
      edges {
        node {
          data {
            packs {
              pack {
                document {
                  ... on PrismicPack {
                    uid
                    data {
                      ...prismicPackData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
