import gsap from "gsap"
import { gsapParams } from "./common"

const selectors = {
  wrapper: '.tpl-packages',
  page: {
    bottom: '.tpl-packages__bottom',
  },
  list: {
    wrapper: '.tpl-packages .list-pack',
    items: '.tpl-packages .list-pack__item'
  },
  filters: {
    wrapper: '.tpl-packages .list--filters',
    items: '.tpl-packages .list--filters .list__item '
  }
}

const setEntry = () => {
  gsap.set(selectors.filters.items, { ...gsapParams.fadeOutDown30 })
  gsap.set(selectors.list.items, { ...gsapParams.fadeOutDown30 })
}

const animateEntry = () => {
  const tl = gsap.timeline()
  tl.to(selectors.filters.items, { ...gsapParams.fadeInUp, duration: .6, stagger: 0.025 }, 0)
  tl.to(selectors.list.items, { ...gsapParams.fadeInUp, duration: .6, stagger: 0.025 }, 0)
  return tl
}

export {
  animateEntry,
  setEntry
}